import fetch from "./fetch";

const MMB03_URL = () => "/PC/WPC_MMB03";
const MMB03_01_URL = () => "/PC/WPC_MMB03_01";
const MMB03_02_URL = () => "/PC/WPC_MMB03_02";
const MMB03_03_URL = () => "/PC/WPC_MMB03_03";
const MMB03_04_URL = () => "/PC/WPC_MMB03_04";

export default {
  getMmb03: fetch("GET", MMB03_URL),
  getMmb0301: fetch("GET", MMB03_01_URL),
  getMmb0302: fetch("GET", MMB03_02_URL),
  getMmb0303: fetch("GET", MMB03_03_URL),
  getMmb0304: fetch("GET", MMB03_04_URL),
};
