<template>
    <!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon-left">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10136')}}
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
    <!-- // nav -->
	<div id="container" class="container">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub mypage">
			<!-- content -->
			<section class="content">
				<div class="row">
					<div class="inner">
						<div class="inner__top">
							<p class="inner__text inner__text--large" v-html="t('10408')">
							</p>
						</div>
						<div class="inner__wrap">
							<div class="frms">
								<!-- 비밀번호 -->
								<div class="frms__item">
									<div class="frms__wrap">
										<div class="flex">
											<input type="password" class="ipt-frm" :placeholder="t('10772')" v-model="password"><!--재번역 체크-->
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
    <div id="navigation" class="navigation navigation--noborder">
		<div class="btns btns--centered">
			<button type="button" class="btn" @click="checkPassword" :class=" password.length >= 8 ? 'btn-confirm' : 'btn-disabled'">확인</button>
		</div>
	</div>
	<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import api from "@/urls/mmb03";
import ErrorAlert from "@/components/layers/Message_alert";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 
export default {
	components: {
		ErrorAlert,
	},
	setup: function() {
		
		const router = useRouter();
		const store = useStore();
		const openErrorAlert = ref(false)
		const userData = computed(() => store.state.userData);
		const { t }= useI18n() //번역필수 모듈
		const password = ref("");

		const checkPassword = async () =>{
			if(password.value.length <= 0) { return; }

			const data = await api.getMmb0301({
				mem_id: userData.value.mem_id,
				passwd: password.value,
			});
			
			if(data.data.Res_cd == -1){
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: "비밀번호를 확인해 주세요."});
				openErrorAlert.value = true;
				//alert("비밀번호를 확인해 주세요.");
			}
			else{
				router.replace("/mypage/edit");
			}
		}

		const goBack = () => {
			router.go(-1);
		};

		onMounted(()=>{
			if(userData.value.sns_join_flag !== ""){
				router.replace("/mypage/edit");
			}
		})

		return {
			goBack,
			userData,
			password,
			checkPassword,
			openErrorAlert,
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
			t,
			i18n
		};
	}
}
</script>